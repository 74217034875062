class ScrollIneractive {
  constructor(id) {
    this.button = document.getElementById(id);
    this.dotts = document.querySelectorAll('.LineScrollDott');
    this.lastPosition = 0;
    if (this.button) {
      this.update = this.update.bind(this);
      setTimeout(() => {
        this.init();
      }, 2000);
      window.addEventListener(
        'resize',
        () => {
          this.destroy();
          this.init();
        },
        { passive: true },
      );
    }
  }

  init() {
    this.headerHeight = document.getElementById('header').clientHeight;
    this.vieportWithoutHeaderHeightHalf =
      (document.documentElement.clientHeight - this.headerHeight) / 1.5;
    this.buttonOffsetTop = this.getTotalOffsetTop(
      document.getElementById('LineScrollStart'),
    );
    this.dottsOffsetTop = this.dotts?.length
      ? [...this.dotts].map((el) => this.getTotalOffsetTop(el))
      : [];
    this.update();
  }

  destroy() {
    cancelAnimationFrame(this.animationFrameId);
  }

  getOffset(element) {
    const rect = element.getBoundingClientRect();
    const distanceToTop = rect.top + window.scrollY;
    return distanceToTop + this.headerHeight;
  }

  getTotalOffsetTop(element) {
    var totalOffsetTop = 0;

    while (element) {
      totalOffsetTop += element.offsetTop;
      element = element.offsetParent;
    }

    return totalOffsetTop + this.headerHeight;
  }

  update() {
    const currentScrollPosition =
      document.documentElement.scrollTop +
      this.headerHeight +
      this.vieportWithoutHeaderHeightHalf;
    if (this.lastPosition == currentScrollPosition) {
      this.animationFrameId = requestAnimationFrame(this.update);
      return false;
    }

    this.dotts.forEach((el, index) => {
      if (currentScrollPosition > this.dottsOffsetTop[index]) {
        el.parentElement.parentElement.classList.add('active');
      } else {
        el.parentElement.parentElement.classList.remove('active');
      }
    });

    if (currentScrollPosition > this.buttonOffsetTop) {
      this.button.style.setProperty(
        '--tw-translate-y',
        `${currentScrollPosition - this.buttonOffsetTop}px`,
      );
    }
    this.lastPosition = currentScrollPosition;
    this.animationFrameId = requestAnimationFrame(this.update);
  }
}
export default ScrollIneractive;
